import React from 'react';
import { Box, styled } from '@mui/material';
import PaymentSectionContainer from './components/PaymentSectionContainer';
import PaymentMethodSection from '../PaymentMethodSection';
import { FormikProps } from 'formik';
import { PaymentSettings, PaymentType } from '@wls-solucoes/lets-eat-types';
import AccountPayment from '../../../../../../../../modules/assets/AccountPayment';
import PaymentDollar from '../../../../../../../../modules/assets/PaymentDollar';

const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 32,
}));

interface PaymentDialogContentProps {
  showOnlinePayments: boolean;
  hasAutomaticPix: boolean;
  hasPaymentsOnDelivery: boolean;
  formik: FormikProps<any>;
  paymentSettings?: PaymentSettings;
  handleSubmit: (paymentType: PaymentType) => void;
}

const PaymentDialogContent: React.FC<PaymentDialogContentProps> = ({
  showOnlinePayments,
  hasAutomaticPix,
  hasPaymentsOnDelivery,
  paymentSettings,
  handleSubmit,
  formik,
}) => {
  const acceptMercadoPago = paymentSettings?.acceptMercadoPago;
  const acceptPix = paymentSettings?.acceptPix;
  const acceptCash = paymentSettings?.acceptCash;
  const acceptCredit = paymentSettings?.acceptCredit;
  const acceptDebit = paymentSettings?.acceptDebit;
  const acceptVoucher = paymentSettings?.acceptVoucher;
  const acceptCardPaymentsWithMercadoPago =
    paymentSettings?.acceptCardPaymentsWithMercadoPago;

  return (
    <Container>
      {showOnlinePayments ? (
        <PaymentSectionContainer
          icon={<PaymentDollar />}
          title="Pagamento online"
          testId="online-payment-methods-container"
        >
          {acceptPix && (
            <PaymentMethodSection
              handleSubmit={handleSubmit}
              hideTitle
              formik={formik}
              paymentType={
                hasAutomaticPix ? PaymentType.shipay : PaymentType.pix
              }
            />
          )}

          {acceptMercadoPago && (
            <PaymentMethodSection
              handleSubmit={handleSubmit}
              hideTitle
              formik={formik}
              paymentType={PaymentType.mercadoPago}
            />
          )}
          {acceptCardPaymentsWithMercadoPago && (
            <PaymentMethodSection
              handleSubmit={handleSubmit}
              hideTitle
              formik={formik}
              paymentType={PaymentType.mercadoPagoCardPayment}
            />
          )}
        </PaymentSectionContainer>
      ) : null}

      {hasPaymentsOnDelivery && (
        <PaymentSectionContainer
          icon={<AccountPayment />}
          title="Pagamento na entrega"
        >
          {acceptCash && (
            <PaymentMethodSection
              handleSubmit={handleSubmit}
              hideTitle
              formik={formik}
              paymentType={PaymentType.cash}
            />
          )}

          {acceptCredit && (
            <PaymentMethodSection
              handleSubmit={handleSubmit}
              formik={formik}
              paymentType={PaymentType.credit}
              flags={paymentSettings.creditCardFlags}
            />
          )}

          {acceptDebit && (
            <PaymentMethodSection
              handleSubmit={handleSubmit}
              formik={formik}
              paymentType={PaymentType.debit}
              flags={paymentSettings.debitCardFlags}
            />
          )}

          {acceptVoucher && (
            <PaymentMethodSection
              handleSubmit={handleSubmit}
              formik={formik}
              paymentType={PaymentType.voucher}
              flags={paymentSettings.voucherCardFlags}
            />
          )}
        </PaymentSectionContainer>
      )}
    </Container>
  );
};

export default PaymentDialogContent;
